import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I love morse code, since not many people know it I feel special knowing it, my grandpa taught me. My grandpa and I were very close, he was my best friend, he was like a father to me. My dad left for the army two years ago, I miss him, he writes to me every day telling me how it is there, he is a pilot. I have no clue how I am his son because I am terrified of heights, the fearless genes must have gotten lost somewhere.`}</p>
    <p>{`My mother is struggling, I see it, my sister sees it even our cat Whiskers sees it. Since I am the ‘man’ of the house now I feel the responsibility to look after her, I try my best. My grandpa and my mom never talked too much, even though we live across a lake from him. From my window I see his bedroom window, we would always use morse code at night because my mom doesn’t let me sleep over at his house she says “it’s too dangerous; that house is twice as old as I am”. So we used morse code with our bedroom lights.`}</p>
    <p>{`When my mom told me grandpa passed away I didn’t take it too well, I stayed in my room in the corner next to Whiskers bed, barely eating for the whole week. My mom said that we couldn’t afford to keep grandpas house and that it was too old anyway, I knew she meant she wanted to tear it down, she just didn’t want to say it out loud. I was mad, she knew how much I cared about grandpa, and his house was the only thing left for me to hold on to.`}</p>
    <p>{`It was Tuesday, exactly 12 days since grandpa passed, I sat on my windowsill looking at how the snow fell, twirling and twisting in the wind. For some reason, I got the weird thought of saying “I miss you” in morse code, just in case he was still there. In my defense, the whole day seemed weird. So I turn my light off and on hoping I will get a response. 5 minutes pass, no response, I am about to go to bed but suddenly the light in grandpa’s window turns on. I almost scream, quickly as if my house was on fire, I put on snow pants, boots, and my coat. And run across the frozen lake barely managing to stay on my feet, the wind is cold I feel every muscle in my face tense up because it hurts, but I don’t stop. When I get to grandpa’s house I reach into the hole under the windowsill, that’s where grandpa always left an extra key for me, to my surprise it’s still there. I open the lock with my hands trembling from the freezing weather or the adrenaline rushing through every cell of my body. I open the door and rush up the stairs almost tripping to the 5th step because grandpa never fixed it. When I get to the top I freeze, I try to move closer to the door and open it but my body stays frozen. I can barely breathe, my toes tingle and my stomach is in a million knots, I take a deep breath and take a step towards the door. I walk in.`}</p>
    <p>{`All of grandpas books are still on the floor and his bet is still un-made, it’s like time froze. I raise my eyes to look at the lamp that magically turned on and I see a raccoon on my grandpa’s bedside table, it looks at me with his beady eyes almost trying to tell me something, then he hisses at me and jumps, the raccoon’s leg got caught in the cable and pulled the lamp down, it fell, a piece of it broke off. I picked up the broken lamp, it was grandpa’s favorite. I walk over to grandpa’s table where he keeps drawings of ships and planes, under one of the drawings I notice a hole carved in the table. “That wasn’t here before,” I say looking up as if I was on some show being filmed. I move the sketches and find a pattern of lines and dots, at first I thought it was just the table design but then I noticed how uneven it is. I run my cold fingers along the pattern, recognizing letters, u, n, d, e, r, under. It says under!  I continue decoding.`}</p>
    <p>{`After a couple of minutes of struggling I decode “Under the ice”. I look out the window, it’s not snowing anymore, it looks calm outside as if everything froze. I look back at the table and whisper “grandpa”. I sprint down the stairs forgetting that one of the steps is broken, I fall, I stand up, not even noticing the hole in my coat I run outside I fall on my knees and start digging and moving the snow reveal the ice. There are tears in my eyes. I am desperate to find something, anything, why would grandpa leave that note? What’s under there?`}</p>
    <p>{`I start jumping, stomping on the ice trying to break it. At that moment I didn’t realize that ice will eventually break and I would fall, probably freezing to death, but did I stop? No. Suddenly I see something move under the ice, it’s big and shiny. I stop jumping and my breathing slows down a bit, my heart, however, does not. The giant fish swims up and starts hitting the ice with its huge body, trying to break it, I start jumping as well. I hear a crack, that’s when I know I’m in trouble, I didn’t react quickly enough, the ice breaks and I fall. The cold water is all around me, but I feel calm, I close my eyes and relax my body.`}</p>
    <p>{`When I open my eyes I am on top of the ice, cold and shivering. I look down and see the fish, it swims up and jumps out of the water revealing its scales. They are shiny and bright, purple, pink, and blue. The fish dives back into the water, I wait, to see it again. It swims up and almost smiles at me. “Grandpa it’s you” I say, almost waiting for reassurance, but I know it’s him I feel his presence. The fish stays silent, tears start rolling down my cheeks, almost instantly freezing. “I miss you” I say. “I know. I’m right here” the fish answers. For a moment I stop breathing. When I get oxygen back into my lungs I bend down and hug him, even though it is freezing outside and I am in icy clothes I feel grandpa’s warmth against my skin. After I let go he smiles one more time and swims away. I sit there staring at the ice with tears running down my cheeks, smiling.`}</p>
    <p>{`“Oh god! Are you okay?! What happened?” I hear my mother scream at the top of her lungs while running towards me. I fell asleep on the ice apparently, the hole is still there and my clothes have completely frozen, and they are stuck to the ice. I don’t tell her about grandpa, I just say I saw someone trying to break into his house so I ran to stop them. My mother smiles and takes me home.`}</p>
    <p>{`Something about that day changed me and my mother, I didn’t feel so alone anymore, and my mother agreed to keep the house. She said she will even get it fixed. It has been two years since that happened and I still remember it like it was yesterday, I never saw the fish again though, but I know it’s still here, I feel it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      